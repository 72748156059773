import React from 'react';
import { Link } from 'gatsby';
import '../style/menu.scss';
import { FormattedMessage } from 'react-intl';
import { myContext } from '../state/provider';

function Menu(props) {
  const langSelected = props.langs.find((lang) => lang.selected).langKey;
  return (
    <myContext.Consumer>
      {(context) => (
        <div className="menu__main">
          <h1>inhalt_</h1>
          <ul className="menu__main__sub">
            <li>
              <Link
                to={`/${langSelected}/`}
                activeClassName="menu__main__sub--selected"
                onClick={(e) => context.resetFilters()}
              >
                <FormattedMessage id="menuProjects" />
              </Link>
            </li>
            <li>
              <Link
                to={`/${langSelected}/contact`}
                activeClassName="menu__main__sub--selected"
              >
                <FormattedMessage id="menuContact" />
              </Link>
            </li>
          </ul>
        </div>
      )}
    </myContext.Consumer>
  );
}

export default Menu;
