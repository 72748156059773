import React from 'react';
import Helmet from 'react-helmet';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';
import 'intl';
import Menu from '../components/menu';
import MenuLanguages from '../components/selectLanguage';

const Layout = ({ children, location, i18nMessages }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
          seo: markdownRemark(fileAbsolutePath: { regex: "/(seo/seo.md)/" }) {
            frontmatter {
              keyWords
              description
            }
          }
        }
      `}
      render={(data) => {
        const url = location.pathname;
        const seo = data.seo;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
        let langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        );
        // Fix double slash je ne pige pas pourquoi ...
        langsMenu.forEach(menu => menu.link = menu.link.replace('//', '/'));
        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <div>
              <Helmet
                title="Inhalt_"
                meta={[
                  { name: 'description', content: seo.frontmatter.description },
                  {
                    name: 'keywords',
                    content: seo.frontmatter.keyWords.join(', '),
                  },
                ]}
              >
                <html lang={langKey} />
              </Helmet>
              {/* <Menu langs={langsMenu} />
              <MenuLanguages langs={langsMenu} /> */}
              <div class="menu__site__languages">
                <Menu langs={langsMenu} />
                <MenuLanguages langs={langsMenu} />
              </div>
              <div className="page">{children}</div>
            </div>
          </IntlProvider>
        );
      }}
    />
  );
};

// Layout.propTypes = {
//   children: PropTypes.func,
// }

export default Layout;
