import React from "react"
import { Link } from "gatsby"
import "../style/selectLanguage.scss"

function MenuLanguages(props) {
  let subMenu = <ul className="menu__languages__sub">
    <li>
      <Link to={props.langs.find(lang => lang.langKey === 'en').link} activeClassName="menu__languages__sub--selected">en</Link>
    </li>
    <li>
      <Link to={props.langs.find(lang => lang.langKey === 'fr').link} activeClassName="menu__languages__sub--selected">fr</Link>
    </li>
  </ul>
  return <div className="menu__languages">
    {subMenu}
  </div>
}

export default MenuLanguages